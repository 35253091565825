import React, { useState, useEffect } from 'react';
import './App.css';

const NUM_TILES = 25; // Number of tiles you want to display

const generateRandomColor = () => {
  const hue = 200 + Math.floor(Math.random() * 60); // Hue values between 200 and 260 (shades of blue)
  const saturation = Math.floor(Math.random() * 100); // Saturation values between 0 and 100
  const lightness = Math.floor(Math.random() * 50) + 25; // Lightness values between 25 and 75
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const Tile = () => {
  const [color, setColor] = useState(generateRandomColor());

  useEffect(() => {
    const interval = setInterval(() => {
      setColor(generateRandomColor());
    }, 10000); // Change color every 10000 milliseconds (10 seconds)

    return () => clearInterval(interval);
  }, []);

  return <div className="tile" style={{ backgroundColor: color }} />;
};

function App() {
  const tiles = Array.from({ length: NUM_TILES }).map((_, index) => (
    <Tile key={index} />
  ));

  return (
    <div className="tile-container">
      {tiles}
    </div>
  );
}

export default App;
